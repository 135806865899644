import React from "react";

const StackIcon = ({ size, color }) => {
  const finalSize = typeof size === "undefined" ? 28 : size;
  const finalColor = typeof color === "undefined" ? "#fff" : color;

  return (
    <svg
      width={finalSize}
      height={finalSize * 1.107142857142857}
      viewBox="0 0 28 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 18.0522C14.5801 18.0522 15.1338 17.8281 15.8721 17.4062L26.0762 11.5C27.2231 10.8145 27.7637 10.2212 27.7637 9.29834C27.7637 8.37549 27.2231 7.78223 26.0762 7.09668L15.8721 1.19043C15.1338 0.768555 14.5801 0.557617 14 0.557617C13.4067 0.557617 12.853 0.768555 12.1147 1.19043L1.92383 7.09668C0.763672 7.78223 0.223145 8.37549 0.223145 9.29834C0.223145 10.2212 0.763672 10.8145 1.92383 11.5L12.1147 17.4062C12.853 17.8281 13.4067 18.0522 14 18.0522ZM14 14.9541C13.8286 14.9541 13.6836 14.9014 13.4858 14.7959L4.0332 9.43018C3.98047 9.39062 3.9541 9.36426 3.9541 9.29834C3.9541 9.24561 3.98047 9.20605 4.0332 9.17969L13.4858 3.81396C13.6836 3.69531 13.8286 3.64258 14 3.64258C14.1582 3.64258 14.3032 3.69531 14.501 3.81396L23.9536 9.17969C24.0063 9.20605 24.0327 9.24561 24.0327 9.29834C24.0327 9.36426 24.0063 9.39062 23.9536 9.43018L14.501 14.7959C14.3032 14.9014 14.1582 14.9541 14 14.9541ZM14 24.6045C14.5405 24.6045 15.0283 24.354 15.7007 23.9585L26.6167 17.5381C27.3682 17.103 27.7769 16.5229 27.7769 15.8242C27.7769 15.1255 27.3418 14.5718 26.8145 14.2422L14.9229 21.1372C14.5537 21.3613 14.2769 21.4932 14 21.4932C13.71 21.4932 13.4331 21.3613 13.064 21.1372L1.17236 14.2422C0.658203 14.5718 0.223145 15.1255 0.223145 15.8242C0.223145 16.5229 0.631836 17.103 1.37012 17.5381L12.2861 23.9585C12.9585 24.354 13.4463 24.6045 14 24.6045ZM14 30.814C14.5405 30.814 15.0283 30.5635 15.7007 30.168L26.6167 23.7607C27.355 23.3257 27.7769 22.7324 27.7769 22.0337C27.7769 21.335 27.3418 20.7812 26.8145 20.4517L14.9229 27.3599C14.5537 27.5708 14.2769 27.7026 14 27.7026C13.71 27.7026 13.4331 27.5708 13.064 27.3599L1.17236 20.4517C0.658203 20.7812 0.223145 21.335 0.223145 22.0337C0.223145 22.7324 0.631836 23.3257 1.37012 23.7607L12.2861 30.168C12.9585 30.5635 13.4463 30.814 14 30.814Z"
        fill={finalColor}
      />
    </svg>
  );
};

export default StackIcon;
