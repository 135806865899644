import { AUTH_ACTIONS } from "./Actions";
import { initialState } from "./AuthContext";

export const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_ACTIONS.SET_AUTHENTICATED_USER:
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
        settings: action.payload.settings,
      };
    case AUTH_ACTIONS.SET_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.payload.avatar,
        },
      };

    case AUTH_ACTIONS.SET_ROI_MATRIX_UNIT_SIZE:
      return {
        ...state,
        settings: {
          ...state.settings,
          roiMatrix: {
            unitSize: action.payload.unitSize,
          },
        },
      };

    case AUTH_ACTIONS.SET_SETTINGS:
      return {
        ...state,
        settings: action.payload.settings,
      };

    default:
      return {
        ...state,
      };
  }
};
