const styles = {
  grey: {
    backgroundColor: "rgba(255,255,255,0.03)",
  },
  green: {
    backgroundColor: "rgba(255,255,255,0.03)",
    color: "#21993b",
  },
};

const grades = [
  { label: "A+", isActive: true },
  { label: "A", isActive: true },
  { label: "A-", isActive: true },
  { label: "B+", isActive: true },
  { label: "B", isActive: true },
  { label: "B-", isActive: true },
  { label: "C+", isActive: true },
  { label: "C", isActive: true },
  { label: "C-", isActive: true },
  { label: "D+", isActive: true },
  { label: "D", isActive: true },
  { label: "D-", isActive: true },
  // { label: "E+", isActive: true },
  // { label: "E", isActive: true },
  // { label: "E-", isActive: true },
  { label: "F+", isActive: true },
  { label: "F", isActive: false },
  { label: "F-", isActive: false },
];

const units = [
  { label: 8, isActive: true },
  { label: 7.5, isActive: true },
  { label: 7, isActive: true },
  { label: 6, isActive: true },
  { label: 5.5, isActive: true },
  { label: 5, isActive: true },
  { label: 3.7, isActive: true },
  { label: 3.2, isActive: true },
  { label: 2.2, isActive: true },
  { label: 1.9, isActive: true },
  { label: 1.4, isActive: true },
  { label: 1, isActive: true },
  // { label: 0.9, isActive: true },
  // { label: 0.8, isActive: true },
  // { label: 0.7, isActive: true },
  { label: 0.65, isActive: true },
  { label: 0.5, isActive: false },
  { label: 0.4, isActive: false },
];

const roiMatrixConfig = {
  headers: {
    grades,
    units,
  },
  groups: [
    {
      isVisible: true,
      label: "NFL",
      nickname: "nfl",
      ranges: [
        {
          max: 2000000,
          min: 1800000,
          maxLabel: ">$2M",
          minLabel: "$1.8M",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[0].label,
            min: grades[0].label,
          },
          style: styles.grey,
          units: {
            max: units[0].label,
            min: units[0].label,
          },
        },
        {
          max: 1799999,
          min: 1600000,
          maxLabel: "$1.7M",
          minLabel: "$1.6M",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[1].label,
            min: grades[1].label,
          },
          style: styles.grey,
          units: {
            max: units[1].label,
            min: units[1].label,
          },
        },
        {
          max: 1599999,
          min: 1400000,
          maxLabel: "$1.5M",
          minLabel: "$1.4M",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[2].label,
            min: grades[2].label,
          },
          style: styles.grey,
          units: {
            max: units[2].label,
            min: units[2].label,
          },
        },
        {
          max: 1399999,
          min: 1000000,
          maxLabel: "$1.3M",
          minLabel: "$1M",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[3].label,
            min: grades[3].label,
          },
          style: styles.grey,
          units: {
            max: units[3].label,
            min: units[3].label,
          },
        },
        {
          max: 999999,
          min: 609000,
          maxLabel: "$999K",
          minLabel: "$609K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[4].label,
            min: grades[4].label,
          },
          style: styles.grey,
          units: {
            max: units[4].label,
            min: units[4].label,
          },
        },
        {
          max: 608999,
          min: 553000,
          maxLabel: "$608K",
          minLabel: "$553K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[5].label,
            min: grades[5].label,
          },
          style: styles.grey,
          units: {
            max: units[5].label,
            min: units[5].label,
          },
        },
        {
          max: 552999,
          min: 456000,
          maxLabel: "$552K",
          minLabel: "$500K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[6].label,
            min: grades[6].label,
          },
          style: styles.grey,
          units: {
            max: units[6].label,
            min: units[6].label,
          },
        },
        {
          max: 455999,
          min: 431000,
          maxLabel: "$499K",
          minLabel: "$431K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[7].label,
            min: grades[7].label,
          },
          style: {},
          units: {
            max: units[7].label,
            min: units[7].label,
          },
        },
        {
          max: 430999,
          min: 376000,
          maxLabel: "$430K",
          minLabel: "$376K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[8].label,
            min: grades[8].label,
          },
          style: {},
          units: {
            max: units[8].label,
            min: units[8].label,
          },
        },
        {
          max: 375999,
          min: 320000,
          maxLabel: "$375K",
          minLabel: "$320K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[9].label,
            min: grades[9].label,
          },
          style: {},
          units: {
            max: units[9].label,
            min: units[9].label,
          },
        },
        {
          max: 319999,
          min: 290000,
          maxLabel: "$319K",
          minLabel: "$290K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[10].label,
            min: grades[10].label,
          },
          style: {},
          units: {
            max: units[10].label,
            min: units[10].label,
          },
        },
        {
          max: 289999,
          min: 174000,
          maxLabel: "$289K",
          minLabel: "$174K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[11].label,
            min: grades[11].label,
          },
          style: {},
          units: {
            max: units[11].label,
            min: units[11].label,
          },
        },
        {
          max: 173999,
          min: 114000,
          maxLabel: "$173K",
          minLabel: "$114K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[12].label,
            min: grades[12].label,
          },
          style: {},
          units: {
            max: units[12].label,
            min: units[12].label,
          },
        },
        {
          max: 113999,
          min: 75000,
          maxLabel: "$113K",
          minLabel: "$75K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[13].label,
            min: grades[13].label,
          },
          style: {},
          units: {
            max: units[13].label,
            min: units[13].label,
          },
        },
        {
          max: 74999,
          min: 0,
          maxLabel: "$75K",
          minLabel: "$0K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[14].label,
            min: grades[14].label,
          },
          style: {},
          units: {
            max: units[14].label,
            min: units[14].label,
          },
        },
      ],
    },
    {
      isVisible: true,
      label: "CFB",
      nickname: "cfb",
      ranges: [
        {
          max: 548000,
          min: 375000,
          maxLabel: ">$548K",
          minLabel: "$375K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[0].label,
            min: grades[0].label,
          },
          style: styles.green,
          units: {
            max: units[0].label,
            min: units[0].label,
          },
        },
        {
          max: 374999,
          min: 265000,
          maxLabel: "$375K",
          minLabel: "$265K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[1].label,
            min: grades[1].label,
          },
          style: styles.grey,
          units: {
            max: units[1].label,
            min: units[1].label,
          },
        },
        {
          max: 264999,
          min: 201000,
          maxLabel: "$265K",
          minLabel: "$201K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[2].label,
            min: grades[2].label,
          },
          style: styles.grey,
          units: {
            max: units[2].label,
            min: units[2].label,
          },
        },
        {
          max: 200999,
          min: 191000,
          maxLabel: "$201K",
          minLabel: "$191K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[3].label,
            min: grades[3].label,
          },
          style: styles.grey,
          units: {
            max: units[3].label,
            min: units[3].label,
          },
        },
        {
          max: 190999,
          min: 176000,
          maxLabel: "$191K",
          minLabel: "$176K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[4].label,
            min: grades[4].label,
          },
          style: {},
          units: {
            max: units[4].label,
            min: units[4].label,
          },
        },
        {
          max: 175999,
          min: 153000,
          maxLabel: "$176K",
          minLabel: "$153K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[5].label,
            min: grades[5].label,
          },
          style: {},
          units: {
            max: units[5].label,
            min: units[5].label,
          },
        },
        {
          max: 152999,
          min: 125000,
          maxLabel: "$153K",
          minLabel: "$125K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[6].label,
            min: grades[6].label,
          },
          style: {},
          units: {
            max: units[6].label,
            min: units[6].label,
          },
        },
        {
          max: 124999,
          min: 113000,
          maxLabel: "$125K",
          minLabel: "$113K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[7].label,
            min: grades[7].label,
          },
          style: {},
          units: {
            max: units[7].label,
            min: units[7].label,
          },
        },
        {
          max: 112999,
          min: 96000,
          maxLabel: "$113K",
          minLabel: "$96K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[8].label,
            min: grades[8].label,
          },
          style: {},
          units: {
            max: units[8].label,
            min: units[8].label,
          },
        },
        {
          max: 95999,
          min: 77000,
          maxLabel: "$96K",
          minLabel: "$77K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[9].label,
            min: grades[9].label,
          },
          style: {},
          units: {
            max: units[9].label,
            min: units[9].label,
          },
        },
        {
          max: 76999,
          min: 45000,
          maxLabel: "$77K",
          minLabel: "$45K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[10].label,
            min: grades[10].label,
          },
          style: {},
          units: {
            max: units[10].label,
            min: units[10].label,
          },
        },
        {
          max: 44999,
          min: 29000,
          maxLabel: "$45K",
          minLabel: "$29K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[11].label,
            min: grades[11].label,
          },
          style: {},
          units: {
            max: units[11].label,
            min: units[11].label,
          },
        },
        {
          max: 28999,
          min: 22000,
          maxLabel: "$29K",
          minLabel: "$22K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[12].label,
            min: grades[12].label,
          },
          style: {},
          units: {
            max: units[12].label,
            min: units[12].label,
          },
        },
        {
          max: 21999,
          min: 15000,
          maxLabel: "$22K",
          minLabel: "$15K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[13].label,
            min: grades[13].label,
          },
          style: {},
          units: {
            max: units[13].label,
            min: units[13].label,
          },
        },
        {
          max: 14999,
          min: 0,
          maxLabel: "$15K",
          minLabel: "$0K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[14].label,
            min: grades[14].label,
          },
          style: {},
          units: {
            max: units[14].label,
            min: units[14].label,
          },
        },
      ],
    },
    {
      isVisible: true,
      label: "MLB",
      nickname: "mlb",
      ranges: [
        {
          max: 585000,
          min: 500000,
          maxLabel: ">$585K",
          minLabel: "$500K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[0].label,
            min: grades[0].label,
          },
          style: styles.green,
          units: {
            max: units[0].label,
            min: units[0].label,
          },
        },
        {
          max: 499999,
          min: 416000,
          maxLabel: "$500K",
          minLabel: "$416K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[1].label,
            min: grades[1].label,
          },
          style: styles.grey,
          units: {
            max: units[1].label,
            min: units[1].label,
          },
        },
        {
          max: 415999,
          min: 376000,
          maxLabel: "$416K",
          minLabel: "$376K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[2].label,
            min: grades[2].label,
          },
          style: styles.grey,
          units: {
            max: units[2].label,
            min: units[2].label,
          },
        },
        {
          max: 375999,
          min: 300000,
          maxLabel: "$376K",
          minLabel: "$300K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[3].label,
            min: grades[3].label,
          },
          style: styles.grey,
          units: {
            max: units[3].label,
            min: units[3].label,
          },
        },
        {
          max: 299999,
          min: 244000,
          maxLabel: "$300K",
          minLabel: "$244K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[4].label,
            min: grades[4].label,
          },
          style: styles.grey,
          units: {
            max: units[4].label,
            min: units[4].label,
          },
        },
        {
          max: 243999,
          min: 200000,
          maxLabel: "$244K",
          minLabel: "$200K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[5].label,
            min: grades[5].label,
          },
          style: styles.grey,
          units: {
            max: units[5].label,
            min: units[5].label,
          },
        },
        {
          max: 199999,
          min: 163000,
          maxLabel: "$199K",
          minLabel: "$163K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[6].label,
            min: grades[6].label,
          },
          style: {},
          units: {
            max: units[6].label,
            min: units[6].label,
          },
        },
        {
          max: 162999,
          min: 124000,
          maxLabel: "$163K",
          minLabel: "$124K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[7].label,
            min: grades[7].label,
          },
          style: {},
          units: {
            max: units[7].label,
            min: units[7].label,
          },
        },
        {
          max: 123999,
          min: 105000,
          maxLabel: "$124K",
          minLabel: "$105K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[8].label,
            min: grades[8].label,
          },
          style: {},
          units: {
            max: units[8].label,
            min: units[8].label,
          },
        },
        {
          max: 104999,
          min: 92000,
          maxLabel: "$105K",
          minLabel: "$92K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[9].label,
            min: grades[9].label,
          },
          style: {},
          units: {
            max: units[9].label,
            min: units[9].label,
          },
        },
        {
          max: 91999,
          min: 70000,
          maxLabel: "$92K",
          minLabel: "$70K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[10].label,
            min: grades[10].label,
          },
          style: {},
          units: {
            max: units[10].label,
            min: units[10].label,
          },
        },
        {
          max: 69999,
          min: 49000,
          maxLabel: "$70K",
          minLabel: "$49K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[11].label,
            min: grades[11].label,
          },
          style: {},
          units: {
            max: units[11].label,
            min: units[11].label,
          },
        },
        {
          max: 48999,
          min: 33000,
          maxLabel: "$49K",
          minLabel: "$33K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[12].label,
            min: grades[12].label,
          },
          style: {},
          units: {
            max: units[12].label,
            min: units[12].label,
          },
        },
        {
          max: 32999,
          min: 15000,
          maxLabel: "$33K",
          minLabel: "$15K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[13].label,
            min: grades[13].label,
          },
          style: {},
          units: {
            max: units[13].label,
            min: units[13].label,
          },
        },
        {
          max: 14999,
          min: 0,
          maxLabel: "$15K",
          minLabel: "$0K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[14].label,
            min: grades[14].label,
          },
          style: {},
          units: {
            max: units[14].label,
            min: units[14].label,
          },
        },
      ],
    },
    {
      isVisible: true,
      label: "NBA",
      nickname: "nba",
      ranges: [
        {
          max: 360000,
          min: 300000,
          maxLabel: ">$360K",
          minLabel: "$300K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[0].label,
            min: grades[0].label,
          },
          style: styles.green,
          units: {
            max: units[0].label,
            min: units[0].label,
          },
        },
        {
          max: 299999,
          min: 222000,
          maxLabel: "$300K",
          minLabel: "$222K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[1].label,
            min: grades[1].label,
          },
          style: styles.grey,
          units: {
            max: units[1].label,
            min: units[1].label,
          },
        },
        {
          max: 221999,
          min: 194000,
          maxLabel: "$222K",
          minLabel: "$194K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[2].label,
            min: grades[2].label,
          },
          style: styles.grey,
          units: {
            max: units[2].label,
            min: units[2].label,
          },
        },
        {
          max: 193999,
          min: 159000,
          maxLabel: "$194K",
          minLabel: "$159K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[3].label,
            min: grades[3].label,
          },
          style: styles.grey,
          units: {
            max: units[3].label,
            min: units[3].label,
          },
        },
        {
          max: 158999,
          min: 132000,
          maxLabel: "$159K",
          minLabel: "$132K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[4].label,
            min: grades[4].label,
          },
          style: styles.grey,
          units: {
            max: units[4].label,
            min: units[4].label,
          },
        },
        {
          max: 131999,
          min: 112000,
          maxLabel: "$132K",
          minLabel: "$112K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[5].label,
            min: grades[5].label,
          },
          style: styles.grey,
          units: {
            max: units[5].label,
            min: units[5].label,
          },
        },
        {
          max: 111999,
          min: 99000,
          maxLabel: "$112K",
          minLabel: "$99K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[6].label,
            min: grades[6].label,
          },
          style: styles.grey,
          units: {
            max: units[6].label,
            min: units[6].label,
          },
        },
        {
          max: 98999,
          min: 81000,
          maxLabel: "$99K",
          minLabel: "$81K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[7].label,
            min: grades[7].label,
          },
          style: {},
          units: {
            max: units[7].label,
            min: units[7].label,
          },
        },
        {
          max: 80999,
          min: 66000,
          maxLabel: "$81K",
          minLabel: "$66K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[8].label,
            min: grades[8].label,
          },
          style: {},
          units: {
            max: units[8].label,
            min: units[8].label,
          },
        },
        {
          max: 65999,
          min: 50000,
          maxLabel: "$66K",
          minLabel: "$50K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[9].label,
            min: grades[9].label,
          },
          style: {},
          units: {
            max: units[9].label,
            min: units[9].label,
          },
        },
        {
          max: 49999,
          min: 37000,
          maxLabel: "$50K",
          minLabel: "$37K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[10].label,
            min: grades[10].label,
          },
          style: {},
          units: {
            max: units[10].label,
            min: units[10].label,
          },
        },
        {
          max: 36999,
          min: 27000,
          maxLabel: "$37K",
          minLabel: "$27K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[11].label,
            min: grades[11].label,
          },
          style: {},
          units: {
            max: units[11].label,
            min: units[11].label,
          },
        },
        {
          max: 26999,
          min: 17000,
          maxLabel: "$27K",
          minLabel: "$17K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[12].label,
            min: grades[12].label,
          },
          style: {},
          units: {
            max: units[12].label,
            min: units[12].label,
          },
        },
        {
          max: 16999,
          min: 8000,
          maxLabel: "$17K",
          minLabel: "$8K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[13].label,
            min: grades[13].label,
          },
          style: {},
          units: {
            max: units[13].label,
            min: units[13].label,
          },
        },
        {
          max: 7999,
          min: 0,
          maxLabel: "$8K",
          minLabel: "$0K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[14].label,
            min: grades[14].label,
          },
          style: {},
          units: {
            max: units[14].label,
            min: units[14].label,
          },
        },
      ],
    },
    {
      isVisible: true,
      label: "CBB",
      nickname: "cbb",
      ranges: [
        {
          max: 450000,
          min: 300000,
          maxLabel: ">$450K",
          minLabel: "$300K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[0].label,
            min: grades[0].label,
          },
          style: styles.green,
          units: {
            max: units[0].label,
            min: units[0].label,
          },
        },
        {
          max: 299999,
          min: 200000,
          maxLabel: "$300K",
          minLabel: "$200K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[1].label,
            min: grades[1].label,
          },
          style: styles.grey,
          units: {
            max: units[1].label,
            min: units[1].label,
          },
        },
        {
          max: 199999,
          min: 138000,
          maxLabel: "$200K",
          minLabel: "$138K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[2].label,
            min: grades[2].label,
          },
          style: styles.grey,
          units: {
            max: units[2].label,
            min: units[2].label,
          },
        },
        {
          max: 137999,
          min: 126000,
          maxLabel: "$138K",
          minLabel: "$126K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[3].label,
            min: grades[3].label,
          },
          style: styles.grey,
          units: {
            max: units[3].label,
            min: units[3].label,
          },
        },
        {
          max: 125999,
          min: 110000,
          maxLabel: "$126K",
          minLabel: "$110K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[4].label,
            min: grades[4].label,
          },
          style: styles.grey,
          units: {
            max: units[4].label,
            min: units[4].label,
          },
        },
        {
          max: 109999,
          min: 98000,
          maxLabel: "$110K",
          minLabel: "$98K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[5].label,
            min: grades[5].label,
          },
          style: styles.grey,
          units: {
            max: units[5].label,
            min: units[5].label,
          },
        },
        {
          max: 97999,
          min: 88000,
          maxLabel: "$98K",
          minLabel: "$88K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[6].label,
            min: grades[6].label,
          },
          style: {},
          units: {
            max: units[6].label,
            min: units[6].label,
          },
        },
        {
          max: 87999,
          min: 79000,
          maxLabel: "$88K",
          minLabel: "$79K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[7].label,
            min: grades[7].label,
          },
          style: {},
          units: {
            max: units[7].label,
            min: units[7].label,
          },
        },
        {
          max: 78999,
          min: 65000,
          maxLabel: "$79K",
          minLabel: "$65K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[8].label,
            min: grades[8].label,
          },
          style: {},
          units: {
            max: units[8].label,
            min: units[8].label,
          },
        },
        {
          max: 64999,
          min: 57000,
          maxLabel: "$65K",
          minLabel: "$57K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[9].label,
            min: grades[9].label,
          },
          style: {},
          units: {
            max: units[9].label,
            min: units[9].label,
          },
        },
        {
          max: 56999,
          min: 40000,
          maxLabel: "$57K",
          minLabel: "$40K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[10].label,
            min: grades[10].label,
          },
          style: {},
          units: {
            max: units[10].label,
            min: units[10].label,
          },
        },
        {
          max: 39999,
          min: 30000,
          maxLabel: "$40K",
          minLabel: "$30K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[11].label,
            min: grades[11].label,
          },
          style: {},
          units: {
            max: units[11].label,
            min: units[11].label,
          },
        },
        {
          max: 29999,
          min: 20000,
          maxLabel: "$30K",
          minLabel: "$20K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[12].label,
            min: grades[12].label,
          },
          style: {},
          units: {
            max: units[12].label,
            min: units[12].label,
          },
        },
        {
          max: 19999,
          min: 13000,
          maxLabel: "$20K",
          minLabel: "$13K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[13].label,
            min: grades[13].label,
          },
          style: {},
          units: {
            max: units[13].label,
            min: units[13].label,
          },
        },
        {
          max: 12999,
          min: 0,
          maxLabel: "$13K",
          minLabel: "$0K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[14].label,
            min: grades[14].label,
          },
          style: {},
          units: {
            max: units[14].label,
            min: units[14].label,
          },
        },
      ],
    },
    {
      isVisible: true,
      label: "NHL",
      nickname: "nhl",
      ranges: [
        {
          max: 261000,
          min: 200000,
          maxLabel: ">$261K",
          minLabel: "$200K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[0].label,
            min: grades[0].label,
          },
          style: styles.green,
          units: {
            max: units[0].label,
            min: units[0].label,
          },
        },
        {
          max: 199999,
          min: 150000,
          maxLabel: "$200K",
          minLabel: "$150K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[1].label,
            min: grades[1].label,
          },
          style: styles.grey,
          units: {
            max: units[1].label,
            min: units[1].label,
          },
        },
        {
          max: 149999,
          min: 100000,
          maxLabel: "$150K",
          minLabel: "$100K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[2].label,
            min: grades[2].label,
          },
          style: styles.grey,
          units: {
            max: units[2].label,
            min: units[2].label,
          },
        },
        {
          max: 99999,
          min: 75000,
          maxLabel: "$100K",
          minLabel: "$75K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[3].label,
            min: grades[3].label,
          },
          style: styles.grey,
          units: {
            max: units[3].label,
            min: units[3].label,
          },
        },
        {
          max: 74999,
          min: 60000,
          maxLabel: "$75K",
          minLabel: "$60K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[4].label,
            min: grades[4].label,
          },
          style: {},
          units: {
            max: units[4].label,
            min: units[4].label,
          },
        },
        {
          max: 59999,
          min: 54000,
          maxLabel: "$60K",
          minLabel: "$54K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[5].label,
            min: grades[5].label,
          },
          style: {},
          units: {
            max: units[5].label,
            min: units[5].label,
          },
        },
        {
          max: 53999,
          min: 48000,
          maxLabel: "$54K",
          minLabel: "$48K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[6].label,
            min: grades[6].label,
          },
          style: {},
          units: {
            max: units[6].label,
            min: units[6].label,
          },
        },
        {
          max: 47999,
          min: 42000,
          maxLabel: "$48K",
          minLabel: "$42K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[7].label,
            min: grades[7].label,
          },
          style: {},
          units: {
            max: units[7].label,
            min: units[7].label,
          },
        },
        {
          max: 41999,
          min: 36000,
          maxLabel: "$42K",
          minLabel: "$36K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[8].label,
            min: grades[8].label,
          },
          style: {},
          units: {
            max: units[8].label,
            min: units[8].label,
          },
        },
        {
          max: 35999,
          min: 30000,
          maxLabel: "$36K",
          minLabel: "$30K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[9].label,
            min: grades[9].label,
          },
          style: {},
          units: {
            max: units[9].label,
            min: units[9].label,
          },
        },
        {
          max: 29999,
          min: 24000,
          maxLabel: "$30K",
          minLabel: "$24K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[10].label,
            min: grades[10].label,
          },
          style: {},
          units: {
            max: units[10].label,
            min: units[10].label,
          },
        },
        {
          max: 23999,
          min: 18000,
          maxLabel: "$24K",
          minLabel: "$18K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[11].label,
            min: grades[11].label,
          },
          style: {},
          units: {
            max: units[11].label,
            min: units[11].label,
          },
        },
        {
          max: 17999,
          min: 12000,
          maxLabel: "$18K",
          minLabel: "$12K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[12].label,
            min: grades[12].label,
          },
          style: {},
          units: {
            max: units[12].label,
            min: units[12].label,
          },
        },
        {
          max: 11999,
          min: 6000,
          maxLabel: "$12K",
          minLabel: "$6K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[13].label,
            min: grades[13].label,
          },
          style: {},
          units: {
            max: units[13].label,
            min: units[13].label,
          },
        },
        {
          max: 5999,
          min: 0,
          maxLabel: "$6K",
          minLabel: "$0K",
          colspan: 1,
          isActive: true,
          grades: {
            max: grades[14].label,
            min: grades[14].label,
          },
          style: {},
          units: {
            max: units[14].label,
            min: units[14].label,
          },
        },
      ],
    },
  ],
  info: {
    headline:
      "* MLB data is from 2023 since the 2024 season is not complete yet",
    descriptions: [
      `Table last updated on Fri, July 26, 2024. These include playoff figures, v1 did not, and the original idea was to have two tables, one for regular season and one for playoffs, but that was too complicated.`,
      `These figures above are skewed for playoff ROI. This was done becuase not many regaulr season games are to be seen as "max all-in" type bets. However, when we see "playoff money" in the regular season, it's a good sign to max bet.`,
      `Would you rather bet 30 times per season with a win % above 90% or grind out 57% betting tons of games and being stressed chasing / martingaling?`,
      `The grey fields represent when the data starts getting 50/50 in terms of wins/loses, and the green represents heavily skewed in terms of wins.`,
    ],
  },
};

export default roiMatrixConfig;
