import { createContext, useContext } from "react";

export const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const initialState = {
  user: null,
  isAuthenticated: false,
  settings: null,
};
