import React, { useEffect, useReducer } from "react";
import { AuthContext, initialState } from "./AuthContext";
import { AuthReducer } from "./AuthReducer";
import services from "network/api";
import { ACCESS_TOKEN_KEY } from "config/app.config";
import { httpCookies } from "helpers/httpCookies";

export const AuthProvider = (props) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  useEffect(() => {
    const id = httpCookies.get(ACCESS_TOKEN_KEY);

    if (id) {
      pullUser();
    }
  }, []);

  const pullUser = (cb = null) => {
    if (cb) {
      services.user.me(dispatch, cb);
    } else {
      services.user.me(dispatch, (response) => {
        // console.log(response);
      });
    }
  };

  return (
    <AuthContext.Provider value={{ state, dispatch, pullUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};
