import axios from "axios";
import { ACCESS_TOKEN_KEY } from "config/app.config";
import {
  setAuthUserAction,
  setAvatarAction,
} from "contexts/auth/ActionCreator";
import { httpCookies } from "helpers/httpCookies";
import { extractErrorPayload } from "helpers/utils";

const userService = {
  me: (dispatch, cb) => {
    axios({
      url: "/me",
      method: "post",
    })
      .then((response) => {
        httpCookies.set(ACCESS_TOKEN_KEY, response.data.payload.user.id);

        setAuthUserAction(
          {
            user: response.data.payload.user,
            settings: response.data.payload.settings,
          },
          dispatch
        );
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  updateAvatar: (formData, dispatch, cb) => {
    axios({
      url: "/avatar",
      method: "put",
      data: formData,
    })
      .then((response) => {
        setAvatarAction(
          {
            avatar: response.data.payload.avatar,
          },
          dispatch
        );
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  all: (formData, cb) => {
    axios({
      url: `/users?offset=${formData.offset}&limit=${formData.limit}`,
      method: "post",
      data: {
        query: formData.query,
      },
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  updateCredits: (formData, cb) => {
    axios({
      url: `/users/${formData.id}/credits`,
      method: "put",
      data: formData,
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  updateDashboardAccess: (formData, cb) => {
    axios({
      url: `/users/${formData.id}/access`,
      method: "put",
      data: formData,
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  destroy: (formData, cb) => {
    axios({
      url: `/users/${formData.id}`,
      method: "delete",
      data: formData,
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
};

export default userService;
