import axios from "axios";
import {
  setRoiMatrixUnitSizeAction,
  setSettingsAction,
} from "contexts/auth/ActionCreator";
import { extractErrorPayload } from "helpers/utils";

const settingsService = {
  getAllSettings: (dispatch, cb) => {
    axios({
      url: "/settings",
    })
      .then((response) => {
        setSettingsAction(
          {
            settings: response.data.payload.settings,
          },
          dispatch
        );

        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  updateRoiMatrixUnitSize: (formData, dispatch, cb) => {
    axios({
      url: "/settings/roi/matrix/unit/size",
      method: "put",
      data: formData,
    })
      .then((response) => {
        setRoiMatrixUnitSizeAction(
          {
            unitSize: formData.unitSize,
          },
          dispatch
        );

        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
};

export default settingsService;
