import axios from "axios";
import { ACCESS_TOKEN_KEY, ROLE_KEY } from "config/app.config";
import { setAuthUserAction } from "contexts/auth/ActionCreator";
import { httpCookies } from "helpers/httpCookies";
import { extractErrorPayload } from "helpers/utils";

const authService = {
  login: (formData, dispatch, cb) => {
    axios({
      url: "/login",
      method: "post",
      data: formData,
    })
      .then((response) => {
        httpCookies.set(ACCESS_TOKEN_KEY, response.data.payload.user.id);
        httpCookies.set(ROLE_KEY, response.data.payload.user.role.nickname);

        setAuthUserAction(
          {
            user: response.data.payload.user,
            settings: response.data.payload.settings,
          },
          dispatch
        );
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  register: (formData, cb) => {
    axios({
      url: "/register",
      method: "post",
      data: formData,
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  sendPasswordResetLink: (formData, cb) => {
    axios({
      url: "/forgot/password",
      method: "post",
      data: formData,
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  resetPassword: (formData, cb) => {
    axios({
      url: "/reset/password",
      method: "post",
      data: formData,
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  verifyToken: (formData, cb) => {
    axios({
      url: "/forgot/password/token/verify",
      method: "post",
      data: formData,
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  logout: (dispatch, cb) => {
    axios({
      url: "/logout",
      method: "post",
    })
      .then((response) => {
        httpCookies.remove(ACCESS_TOKEN_KEY);
        httpCookies.remove(ROLE_KEY);
        setAuthUserAction(
          {
            user: null,
          },
          dispatch
        );
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
};

export default authService;
