import { AUTH_ACTIONS } from "./Actions";

export const setAuthUserAction = (payload, dispatch) => {
  dispatch({
    type: AUTH_ACTIONS.SET_AUTHENTICATED_USER,
    payload: {
      user: payload.user,
      isAuthenticated: payload.user ? true : false,
      settings: payload.settings,
    },
  });
};

export const setAvatarAction = (payload, dispatch) => {
  dispatch({
    type: AUTH_ACTIONS.SET_AVATAR,
    payload,
  });
};

export const setRoiMatrixUnitSizeAction = (payload, dispatch) => {
  dispatch({
    type: AUTH_ACTIONS.SET_ROI_MATRIX_UNIT_SIZE,
    payload,
  });
};

export const setSettingsAction = (payload, dispatch) => {
  dispatch({
    type: AUTH_ACTIONS.SET_SETTINGS,
    payload,
  });
};
