import Cookies from "js-cookie";
import { decrypt, encrypt } from "./utils";
import { COOKIE_DOMAIN, ENCRYPTION_SALT } from "config/app.config";

const setCookie = (name, value, expires = 30) => {
  if (!value) return null;

  const encrypted = encrypt(ENCRYPTION_SALT, JSON.stringify(value));

  Cookies.set(name, encrypted, { expires, domain: COOKIE_DOMAIN });
};

const getCookie = (name) => {
  const encrypted = Cookies.get(name, { domain: COOKIE_DOMAIN });

  if (typeof encrypted === "undefined") return null;

  const decrypted = decrypt(ENCRYPTION_SALT, encrypted);

  return JSON.parse(decrypted);
};

const removeCookie = (name) => {
  Cookies.remove(name, { domain: COOKIE_DOMAIN });
};

export const httpCookies = {
  set: setCookie,
  get: getCookie,
  remove: removeCookie,
};
