import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
// import { SocketProvider } from "./contexts/socket/SocketProvider";
import { RouterProvider } from "react-router-dom";
import routes from "routes/routes";
import PageLoader from "components/PageLoader/PageLoader";
import { AppProvider } from "contexts/app/AppProvider";
import { AuthProvider } from "contexts/auth/AuthProvider";
//import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AppProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <SocketProvider> */}
      <AuthProvider>
        <Suspense fallback={<PageLoader />}>
          <RouterProvider router={routes} />{" "}
        </Suspense>
      </AuthProvider>

      {/* </SocketProvider> */}
    </ThemeProvider>
  </AppProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
