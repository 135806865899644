import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useAppContext } from "contexts/app/AppContext";
import { toggleAlertAction } from "contexts/app/ActionCreator";

const CustomAlert = React.forwardRef(function (props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Alert = () => {
  const { state, dispatch } = useAppContext();

  const canOpen = state.status && state.message ? true : false;

  const [open, setOpen] = React.useState(false);

  const position = {
    vertical: "bottom",
    horizontal: "center",
  };

  const { vertical, horizontal } = position;

  useEffect(() => {
    if (canOpen) {
      setOpen(true);
    }
  }, [canOpen]);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    toggleAlertAction(
      {
        status: null,
        message: null,
      },
      dispatch
    );
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{ minWidth: "300px" }}
      >
        <CustomAlert
          onClose={handleClose}
          severity={state.status}
          sx={{ width: "100%" }}
        >
          {state.message}
        </CustomAlert>
      </Snackbar>
    </Stack>
  );
};

export default Alert;
