import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { httpCookies } from "helpers/httpCookies";
import { ACCESS_TOKEN_KEY, ROLE_KEY } from "config/app.config";

const Protected = () => {
  const token = httpCookies.get(ACCESS_TOKEN_KEY);
  const role = httpCookies.get(ROLE_KEY);

  return token && role ? <Outlet /> : <Navigate to="/login" />;
};

export default Protected;
