import { createTheme } from "@mui/material";
import { COLORS } from "./settings";

export const theme = createTheme({
  typography: {
    fontFamily: [
      "sf-pro",
      "sans-serif",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    mode: "dark",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%",
        },
        body: {
          backgroundColor: COLORS.dark,
          color: COLORS.fonts.white,
          overflowX: "hidden",
          height: "100%",
          margin: 0,
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: COLORS.dark,
            width: "10px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: COLORS.light,
            minHeight: 30,
            //border: `3px solid ${"#454859"}`,
            border: 0,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#454859",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // textTransform: "initial",
          // backgroundColor: "#fff",
          // "&:hover": {
          //   backgroundColor: "#aaa",
          // },

          borderRadius: "9px",
        },
      },
    },
  },
});
