import { Box, CircularProgress } from "components/Mui";
import styles from "./styles";

const PageLoader = () => {
  return (
    <Box sx={styles.wrapper}>
      <CircularProgress size={30} />
    </Box>
  );
};

export default PageLoader;
