import axios from "axios";
import { extractErrorPayload } from "helpers/utils";

const invitationService = {
  verifyToken: (formData, cb) => {
    axios({
      url: "/invite/token/verify",
      method: "post",
      data: formData,
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  resend: (formData, cb) => {
    axios({
      url: "/invite/resend",
      method: "post",
      data: formData,
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  send: (formData, cb) => {
    axios({
      url: "/invite",
      method: "post",
      data: formData,
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
};

export default invitationService;
