export const VALID_LEAGUES = [
  { id: 99, nickname: "all", name: "ALL" },

  { id: 101, nickname: "nfl", name: "NFL" },
  { id: 102, nickname: "cfb", name: "CFB" },
  { id: 103, nickname: "cbb", name: "CBB" },
  { id: 104, nickname: "nba", name: "NBA" },
  { id: 105, nickname: "mlb", name: "MLB" },
  { id: 106, nickname: "nhl", name: "NHL" },
  { id: 107, nickname: "top10", name: "TOP 10" },
  { id: 108, nickname: "top10roi", name: "ROI 10" },
];

export const COOKIE_DOMAIN = process.env.REACT_APP_DOMAIN;
export const ENCRYPTION_SALT = process.env.REACT_APP_ENCRYPTION_SALT;

export const ACCESS_TOKEN_KEY = process.env.REACT_APP_ACCESS_TOKEN_KEY;
export const ROLE_KEY = "__brl";

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const BASE_URL = process.env.REACT_APP_URL;

export const FILTER_KEY = "__fk";

export const NOT_AVAILABLE = "Not available";

// URLs
export const isProd = true;

export const URL = {
  WEBSITE: isProd ? "https://blockchainbets.money" : "http://localhost:4000",
  DASHBOARD: isProd ? "https://blockchainbets.money" : "http://localhost:4000",
};

export const HIDE_GRADES_FOR_TABLE = ["D+", "D", "D-", "F", "F-"];

// export const TIMEZONE = "America/New_York";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const TIMEZONE =
  typeof timezone !== "undefined" ? timezone : "America/New_York";
