import axios from "axios";
import { extractErrorPayload } from "helpers/utils";

const creditsService = {
  update: (formData, cb) => {
    axios({
      url: "/credits",
      method: "put",
      data: formData,
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
  all: (cb) => {
    axios({
      url: "/credits",
    })
      .then((response) => {
        cb(response.data);
      })
      .catch((error) => {
        cb(extractErrorPayload(error));
      });
  },
};

export default creditsService;
