import { APP_ACTIONS } from "./Actions";

export const toggleAlertAction = (payload, dispatch) => {
  dispatch({
    type: APP_ACTIONS.TOGGLE_ALERT,
    payload,
  });
};

export const setActiveAction = (payload, dispatch) => {
  dispatch({
    type: APP_ACTIONS.SET_ACTIVE,
    payload: payload,
  });
};

export const toggleMobileMenuAction = (dispatch) => {
  dispatch({
    type: APP_ACTIONS.TOGGLE_MOBILE_MENU,
  });
};

export const hideMobileMenuAction = (dispatch) => {
  dispatch({
    type: APP_ACTIONS.HIDE_MOBILE_MENU,
  });
};

export const showMobileMenuAction = (dispatch) => {
  dispatch({
    type: APP_ACTIONS.SHOW_MOBILE_MENU,
  });
};

export const toggleDetailsPanelAction = (payload, dispatch) => {
  dispatch({
    type: APP_ACTIONS.TOGGLE_DETAILS_PANEL,
    payload: {
      isActive: payload.isActive,
    },
  });
};
