import { keyframes } from "@emotion/react";

export const COLORS = {
  extraDark: "#141414",
  dark: "#191a24",
  dark1: "#18181d",
  semiDark: "#4c4e69",
  medium: "#20222f",
  medium1: "#313344",
  light: "#323547",
  lightTransparent: "#32354757",
  blue: "#5aa5fe",
  silver: "#d1d3e0",
  yellow: "#dfc650",
  yellow1: "#ebcb62",
  orange: "#f49a41",
  purple: "#6672cc",
  gray: "#585a6f",
  gray2: "#303d53",
  gray3: "#7e7983",
  gray4: "#6b6872",
  //gray5: "#908f90",
  gray5: "#adadadfa",
  red: "#be5351",
  transparentWhite: "#ffffffb3",
  border: {
    gray: "#2a2c3a",
    gray1: "#464957",
  },
  fonts: {
    gray: "#848692",
    lightGray: "#858693",
    white: "#fcfcfc",
  },
};

export const menuItemLabel = {
  size: "1rem",
  weight: 400,
  letterSpacing: "0.03rem",
};

export const dropdownMenuFontSize = {
  fontSize: ".9rem",
};

export const table = {
  heading: {
    size: "1.2rem",
    weight: 600,
    icon: {
      size: 20,
    },
  },
  header: {
    size: "1.1rem",
    weight: 400,
  },
  cellText: {
    size: "1.2rem",
    weight: 500,
  },

  leftSpacing: "40px",
  rightSpacing: "40px",
  letterSpacing: "0.03rem",
};

export const blinkingAnimation = keyframes`
0%,
49% {
  background-color: #622727;
  
}
50%,
100% {
  background-color: #141414;
 
}
`;
