import React, { useReducer } from "react";
import axios from "axios";
import { AppContext, initialState } from "./AppContext";
import { AppReducer } from "./AppReducer";
import { API_ENDPOINT, BASE_URL } from "config/app.config";
import { toggleAlertAction } from "./ActionCreator";
import Alert from "components/Alert/Alert";

export const AppProvider = (props) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  if (API_ENDPOINT) {
    axios.interceptors.request.use(
      async (config) => {
        config.baseURL = API_ENDPOINT;
        config.withCredentials = true;
        return Promise.resolve(config);
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        if (response.data.status && response.data.message) {
          toggleAlertAction(
            {
              status: response.data.status,
              message: response.data.message,
            },
            dispatch
          );
        }

        return response;
      },
      async (error) => {
        if (error?.response?.status === 401) {
          document.location = BASE_URL + "/login";
        } else {
          if (
            typeof error.response.data !== "undefined" &&
            error.response.data.status &&
            error.response.data.message
          ) {
            toggleAlertAction(
              {
                status: error.response.data.status,
                message: error.response.data.message,
              },
              dispatch
            );
          }
        }

        return Promise.reject(error);
      }
    );
  }

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
      {state.status && state.message ? <Alert /> : null}
    </AppContext.Provider>
  );
};
